﻿
h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 900;
}

.header-image {
    background-color: $color-1-3;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url('../../wwwroot/img/header-image.png'), linear-gradient(to right, $color-1-3, $color-1);
    padding: $gutter;
    padding-left: 400px;
    height: 90px;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    h1 {
        margin: 0;
        font-size: 28px;
        color: white !important;
    }

    @media (max-width: 600px) {
        background-image: linear-gradient(to right, $color-1-3, $color-1);
        padding-left: 30px;

        h1 {
            font-size: 24px !important;
        }
    }
}

@mixin demoItemColors ($color-base, $color-complementary ) {
    h2 {
        color: $color-base;
    }

    .demo-item .bkg {
        background: $color-base;
        background: -moz-linear-gradient(-45deg, $color-base 0%, $color-complementary 100%);
        background: -webkit-linear-gradient(-45deg, $color-base 0%, $color-complementary 100%);
        background: linear-gradient(135deg, $color-base 0%, $color-complementary 100%);
    }
}

.open-in-studio {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    background-color: #2b333b;
    padding: $gutter-md;
    border-radius: 6px 6px 0 0;
    margin-top: auto;
    background: linear-gradient(-45deg, $color-4-2, $color-5, $color-5-2, $color-1);
    background-size: 400% 400%;
    animation: gradient 7.5s ease infinite;

    @media (max-width: $screen-xs-max) {
        margin: 0 $gutter-md;
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }

    h3, a {
        margin: 0 auto;
    }

    h3 {
        margin-bottom: $gutter-md;
        color: white;
        line-height: 1.1em;

        @media (max-width: $screen-xs-max) {
            font-size: 18px;
        }
    }

    a {
        color: white !important;
    }

    .fab {
        border: none;
        color: $gray;
        font-weight: bold;
        background-color: transparent;
        text-transform: uppercase;
        font-size: 11px;
        position: relative;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        outline: none !important;
        padding: 0;

        & + .fab {
            margin-left: $gutter;
        }

        i {
            width: $gutter-lg;
            height: $gutter-lg;
            font-size: $gutter;
            line-height: $gutter-lg;
            text-align: center;
            border-radius: 50px;
            color: $gray-lighter;
            transition: transform .15s cubic-bezier(0, 0.5, 0.5, 1);
            border: 2px solid rgba(white, .33);
        }

        span {
            line-height: 11px;
            margin-top: $gutter-xs;
        }

        &:hover {
            i {
                color: black;
                transform: scale(1.3) translateY(-15%);
                background-color: white;
                -webkit-transition: all .2s ease-out;
                transition: all .2s ease-out;
                mix-blend-mode: screen;
            }
        }
    }
}

.demo-list {
    display: flex;
    flex-wrap: wrap;
    padding: $gutter;

    .demo-category {
        padding: $gutter-sm;

        h2 {
            margin-top: 0;
        }

        img {
            max-width: 85%;
            margin: 10px auto -3% auto;
            text-align: center;
            display: block;
        }

        &:nth-of-type(10n - 1) {
            @include demoItemColors(desaturate($color-2-1, 10%), desaturate($color-2-2, 10%));
        }

        &:nth-of-type(10n - 2) {
            @include demoItemColors(desaturate($color-2, 10%), desaturate($color-2-2, 10%));
        }

        &:nth-of-type(10n - 3) {
            @include demoItemColors(desaturate($color-1-2, 10%), desaturate($color-1-3, 10%));
        }

        &:nth-of-type(10n - 4) {
            @include demoItemColors(desaturate($color-1, 10%), desaturate($color-1-2, 10%));
        }

        &:nth-of-type(10n - 5) {
            @include demoItemColors(desaturate($color-5-2, 10%), desaturate($color-5-3, 10%));
        }

        &:nth-of-type(10n - 6) {
            @include demoItemColors(desaturate($color-5, 10%), desaturate($color-5-2, 10%));
        }

        &:nth-of-type(10n - 7) {
            @include demoItemColors(desaturate($color-4-2, 10%), desaturate($color-4-3, 10%));
        }

        &:nth-of-type(10n - 8) {
            @include demoItemColors(desaturate($color-4, 10%), desaturate($color-4-1, 10%));
        }

        &:nth-of-type(10n - 9) {
            @include demoItemColors(desaturate($color-3-2, 10%), desaturate($color-3-3, 10%));
        }

        &:nth-of-type(10n) {
            @include demoItemColors(desaturate($color-3, 10%), desaturate($color-3-1, 10%));
        }

        .demo-group {
            display: flex;
            flex-wrap: wrap;
            margin: -$gutter-xs;

            .demo-item {
                display: flex;
                flex-direction: column;
                background-color: #2b333b;
                width: 200px;
                color: $gray-light;
                margin: $gutter-xs;
                text-decoration: none;
                transition: all .20s cubic-bezier(0.6, 0, 0.14, 1.45);
                border-radius: 6px;

                @media (max-width: 495px) {
                    min-width: 90%;
                }

                .title {
                    line-break: loose;
                    font-size: 16px;
                    text-align: center;
                    padding: 15px;
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                    margin: auto;
                }

                &:hover {
                    color: $gray-lighter;
                    transform: scale(1.1) translateY(-5px);
                    box-shadow: 0px 5px 20px 2px rgba(0, 0, 0, 0.5);
                }

                .bkg {
                    border-radius: 6px 6px 0 0;
                }

                &.done {
                    position: relative;

                    .bkg {
                        mix-blend-mode: luminosity;
                        opacity: .5;
                    }

                    &:before {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        line-height: 120px;
                        font-size: 50px;
                        top: 0;
                        color: $color-3;
                        /* use !important to prevent issues with browser extensions that change fonts */
                        font-family: 'icomoon' !important;
                        speak: none;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        //line-height: 1;
                        /* Better Font Rendering =========== */
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content: "\e90a";
                    }
                }
            }
        }
    }
}
