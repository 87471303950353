﻿
@use "sass:math";

/* SCROLLBARS */
::-webkit-scrollbar-track {
    background-color: $gray;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: $gray-light;

    &:hover {
        background-color: $gray-lighter;
    }

    &:active {
        background-color: $brand-primary;
    }
}

#root {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.text-muted {
    color: $gray;
}

button, a {
    cursor: pointer;
}

html, body {
    height: 100%;
    width: 100%;
    font-size: $font-size;
}

body {
    background-color: $gray-base;
    font-family: 'lato', Helvetica Neue,Helvetica,Arial,sans-serif;
    color: $gray-light;
    display: flex;
    flex-direction: column;
    line-height: 1.5em;
}

a {
    color: $brand-primary;
    text-decoration: none;

    &:hover {
        color: saturate( lighten( $brand-primary, 15% ), 100% );
    }
}

hr {
    border: none;
    border-top: 1px solid $gray-dark;
}

.btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: $gutter-xs/-2;

    .btn {
        margin: $gutter-xs/2;    
    }
}

@mixin btn-variant($text-color, $background-color) {
    background-color: $background-color;
    color: $text-color;

    &:hover, &:active {
        background-color: saturate( darken( $background-color, 5% ), 0% );
        color: saturate( lighten( $text-color, 10% ), 40% );
    }
}

.btn {
    border: none;
    background-color: $gray-base;
    color: $brand-primary;
    font-weight: bold;
    line-height: $gutter-sm;
    padding: $gutter-xs $gutter-sm;
    border-radius: 1px;
    cursor: pointer;

    &:hover {
        background-color: $gray-dark;
    }

    i {
        margin-right: $gutter-xxs;
    }

    &.btn-primary {
        @include btn-variant($gray-lighter, $brand-primary);
    }

    &.btn-danger {
        @include btn-variant($gray-lighter, $brand-danger-dark);
    }
    
    &.btn-success {
        @include btn-variant($gray-darker, $brand-success-dark);
    }

    &.btn-default {
        @include btn-variant($gray-dark, rgba($gray-lighter, .8));
    }

    &.active {
        @include btn-variant($brand-primary, $gray-lighter);
    }

    &:disabled {
        background-color: $gray !important;
        color: $gray-light !important;
    }
}

.header {
    padding: $gutter-xs $gutter;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    background-color: $gray-base-light;
    max-width: 100vw;

    @media (max-width: 768px) {
        flex-direction: column;
        #selectDemo {
            margin-left: 0;
        }
    }

    .logo {
        display: block;

        img {
            height: 42px;
        }
    }
    .dropdown-settings {
        display: flex;
        align-items: center;
        margin-bottom: math.div($gutter, 5);
    }

    .settings {
        font-size: $gutter;
        transition: color .2s $global-easing;
        color: $gray;
        margin-left: $gutter;

        &:hover {
            color: $gray-lighter;
        }
    }
    .nav-urls {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 11px;
        margin-top: math.div(-$gutter, 5);
        margin-bottom: math.div($gutter, 5);
    }
    .margin-right {
        margin-right: 10px;
    }
    .separator {
        margin: 0 10px;
    }
    a {
        text-decoration: none;
    }
    .cloud-url {
        color: #37c4ac;
        &:hover {
            color: lighten(#37c4ac, 20%);
        }
    }
}

.container {
    display: flex;
    flex-grow: 1;
    position: relative;
}

.sidebar {
    width: 100vw;
    max-width: $sidebar-width;
    background-color: $gray-darker;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
    flex-shrink: 0;
    overflow-y: auto;
    overflow-x: hidden;

    @media (min-width: $screen-sm) {
        transition: width .5s $global-easing-in-out;
        width: $sidebar-width;
    }

    &.conference {
        transition: none !important;

        .sidebar-body {
            transition: none !important;
        }
    }

    .sidebar-body {
        padding: $gutter;
        width: calc(100vw - (#{$gutter}*2));
        max-width: $sidebar-width - ($gutter*2);
        height: 100px;

        @media (min-width: $screen-sm) {
            padding: $gutter-md;
            width: $sidebar-width - ($gutter-md*2);
            transition: opacity .3s .3s $global-easing, transform .3s .3s $global-easing;
        }
    }

    .back-button {
        text-decoration: none;
    }

    .language-select {
        display: flex;
        flex-wrap: wrap;
    }

    .sidebar-heading {
        position: relative;

        .back-button {
            top: -7px;
            position: absolute;
            left: -$gutter-md;
            font-size: 35px;
            width: $gutter-md;
            height: $gutter-md;
            text-align: center;
            line-height: $gutter-md;
        }
    }

    .sidebar-controlls {
        position: sticky;
        top: 0;
        z-index: 1000;
        //text-align:right;
        margin-bottom: -$gutter;

        .collapse-sidebar, .back-button {
            display: block;
            background-color: $gray-dark;
            height: $gutter;
            width: $gutter;
            border: none;
            color: $gray-light;
            line-height: $gutter;
            text-align: center;
        }

        .back-button {
            position: absolute;
            height: 0;
            transition: height .2s $global-easing;
            background-color: $brand-primary;
            color: $gray-lighter;
            overflow: hidden;
        }
    }

    &:not(.small) {

        .icon-expand {
            display: none;
        }
    }


    &.small {
        width: $gutter;
        padding: 0;
        overflow: hidden;

        @media (min-width: $screen-sm) {
            transition: width .5s .1s $global-easing-in-out;
        }

        .back-button {
            height: $gutter;
            transition: height .2s .4s $global-easing;
        }

        .sidebar-body {
            @media (min-width: $screen-sm) {
                transition: opacity .5s $global-easing-in-out, transform .5s $global-easing-in-out;
            }
        }

        .icon-collapse {
            display: none;
        }

        .sidebar-body {
            opacity: 0;
            transform: translateX(-$gutter);
            pointer-events: none;
        }
    }
}

#selectDemo {
    background-color: $gray-darker;
    display: block;
    text-align: center;
    padding: $gutter-xxs $gutter-xs;
    width: fit-content;
    color: $gray-light;
    font-weight: 900;
    border: none;
    margin-left: $gutter;

    option {
        height: 30px;

        &[disabled] {
            margin: 10px 0;
            padding: 10px 0;
            text-transform: uppercase;
            font-size: 10px; 
        }
    }
}

#demo-body-container {
    position: absolute;
    padding: .1px;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

#preview-source-code {
    z-index: $zindex-code;
}

.demo-body {
    flex-grow: 1;
    background-color: $gray-base;
    overflow: auto;
    position: relative;

    .demo-code {
        flex-grow: 1;
        padding: $gutter;
        position: relative;
    }

    .footer-container {
        bottom: 0;
        left: $gutter-xs;
        position: sticky;
        z-index: $zindex-results;
    }

    .results-container {
        overflow: hidden;
        margin: 0 $gutter-xs;
        //transition: height .35s $global-easing-ino-out !important;
        .results-speed {
            font-size: 12px;
            font-weight: 800;
            line-height: 11px;
            display: flex;


            .text-muted {
                font-size: 10px;
                text-transform: uppercase;
                font-weight: normal;
            }
        }

        .minimize-results {
            border: none;
            background-color: transparent;
            padding: 0;
            margin: -$gutter-xs (-$gutter-xs) 0 $gutter-sm;
            color: $brand-primary;
            width: $gutter-lg;
            height: $gutter-lg;
            font-size: $gutter;
            line-height: $gutter-lg;
            text-align: center;
            //position: absolute;
            //top: $gutter-xs;
            //right: $gutter-xs;
            border-radius: 100px;
            transition: background .2s $global-easing-in-out;

            &:hover {
                background-color: $gray-darker;
            }
        }

        .open-results {
            border: none;
            background-color: $brand-primary;
            padding: 10px;
            margin: -$gutter-xs (-$gutter-xs) 0 $gutter-sm;
            color: $gray-lighter;
            text-transform: uppercase;
            border-radius: 20px;
            transition: background .2s $global-easing-in-out;
            font-size: 12px;

            &:hover {
                background-color: fade-out($color: $gray-darker, $amount: 0.33)
            }
        }
    }

    .results {
        padding: $gutter-sm;
        background-color: $gray-dark;
        text-align: center;

        h1, h2 {
            margin: 0;
        }
    }
}

.fab-container {
    z-index: $zindex-fabs;
    position: absolute;
    right: $gutter;
    top: -110px;
    font-size: 11px;
    display: flex;

    .text-msg {
        color: $brand-info-light;
        background-color: adjust-color($brand-info-dark, $lightness: -20%, $saturation: -20%); 
        font-size: 14px;
        height: fit-content;
    }
    
    .fab {
        border: none;
        color: $gray;
        font-weight: bold;
        background-color: transparent;
        text-transform: uppercase;
        font-size: 11px;
        position: relative;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        outline: none !important;
        padding: 0;

        & + .fab {
            margin-left: $gutter;
        }

        i {
            background-color: $gray-dark;
            width: $gutter-lg;
            height: $gutter-lg;
            font-size: $gutter;
            line-height: $gutter-lg;
            text-align: center;
            border-radius: 50px;
            color: $gray-lighter;
            transition: transform .15s cubic-bezier(0, 0.5, 0.5, 1);
        }

        span {
            line-height: 11px;
            margin-top: $gutter-xs;
        }

        &:hover {
            i {
                transform: scale(1.3) translateY(-15%);
            }
        }

        &#openStudio {
            i {
            }
        }

        &#startWalkthrough {
            i {
                background-color: $color-5;
            }
        }

        &#runScript {
            i {
                background-color: $brand-primary;
            }
        }
    }
}
//collapse
.collapse {
    &:not(.show) {
        display: none;
    }
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
}
//dropdowns
// The dropdown wrapper (`<div>`)
.dropdown {
    position: relative;
    margin-top: $gutter/4;
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
// The dropdown menu
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $zindex-dropdown;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    min-width: $dropdown-min-width;
    padding: $gutter-xs 0;
    margin: $gutter-xs 0 0; // override default ul
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;
    background-color: $gray-dark;
    background-clip: padding-box;
    box-shadow: $global-box-shadow;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}
// When enabled Popper.js, reset basic dropdown position
// stylelint-disable no-duplicate-selectors
.dropdown-menu {
    &.placement-right {
        left: auto;
        right: 0;
    }
}
// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
    display: block;
    //    min-width: 100%; // For `<button>`s
    padding: $gutter-xs $gutter-sm;
    clear: both;
    color: $gray-light;
    text-align: inherit; // For `<button>`s
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    background-color: transparent; // For `<button>`s
    border: 0; // For `<button>`s
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    line-height: 12px;

    &.active,
    &:active {
        color: $gray-lighter;
        text-decoration: none;
    }

    &:hover {
        background-color: $gray-darker;
    }

    &.disabled,
    &:disabled {
        color: $gray;
        background-color: transparent;
    }
}

.dropdown-menu.show {
    display: block;
}


.dropdown-item-text {
    display: block;
    padding: $gutter-xs $gutter-xs;
    color: $gray-light;
}

ul, ol {
    &.list-withIcons {
        padding-left: 0;
        margin: 0;

        li {
            line-height: 1.5em;
            margin-bottom: .5em;
            display: flex;

            a {
                font-weight: bold;
                flex-grow: 1;
                text-decoration: 0;
                text-decoration: none;
            }
        }

        [class^="icon-"], [class*=" icon-"] {
            margin-right: $gutter-xs
        }
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


$spin-properties: spin .75s infinite cubic-bezier(0.6, 0.02, 0.4, 0.99);

.spinner {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: $gray-base;
    z-index: $zindex-spinner;
    opacity: 0;
    transition: opacity .3s ease;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 3px solid rgba($gray-lighter,.2);
        border-top-color: $gray-lighter;
        animation: i .75s infinite cubic-bezier(.6,.02,.4,.99);
        -webkit-animation: $spin-properties;
        -moz-animation: $spin-properties;
        -ms-animation: $spin-properties;
        animation: $spin-properties;
    }

    &:not(.active) {
        pointer-events: none;
    }

    &.active {
        opacity: 1;
    }

    &.hidden {
        display: none;
    }
}

@-webkit-keyframes fadein {
    from {
        top: 0;
        opacity: 0;
    }

    to {
        top: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        top: 0;
        opacity: 0;
    }

    to {
        top: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        top: 30px;
        opacity: 1;
    }

    to {
        top: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        top: 30px;
        opacity: 1;
    }

    to {
        top: 0;
        opacity: 0;
    }
}

.toast {
    visibility: hidden;
    background-color: $gray-lighter;
    color: $gray-darker;
    text-align: center;
    border-radius: 3px;
    padding: $gutter-xs $gutter-sm;
    position: fixed;
    z-index: $zindex-toast;
    left: 50%;
    top: 30px;

    &.active {
        visibility: visible;
        -webkit-animation: fadein 0.5s, fadeout 0.5s 4s forwards;
        animation: fadein 0.5s, fadeout 0.5s 4s forwards;
    }

    &.danger {
        color: $gray-lighter;
        background-color: $brand-danger;
    }

    &.warning {
        color: $gray-lighter;
        background-color: $brand-warning;
    }
}

.bg-warning {
    background-color: rgba($brand-warning, .2);
    color: $brand-warning;
}

#dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $zindex-error;

    .message {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: $gray-light;
        color: $gray-darker;
        z-index: $zindex-error + 2;
        border-radius: 3px;
        box-shadow: 0px 5px 20px 0px #000;
        padding: $gutter;
        max-width: 400px;

        h2 {
            margin: 0;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($gray-base, .7);
        z-index: $zindex-error + 1;
    }
}

.results-json, .results-table {
    display: block;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    max-height: 50vh;
}

.results-json {
    overflow: auto !important;
    background-color: $gray-darker !important;
}

.results-table {
    width: 100%;

    table {
        width: 100%;
        max-width: 100%;
        background-color: transparent;
        border: none;
        border-collapse: collapse;

        td, th {
            padding: .75rem;
            vertical-align: top;
            border-top: 1px solid $gray-darker;
        }

        thead th {
            vertical-align: bottom;
            border-bottom: 2px solid $gray-darker;
            color: $gray-lighter;
        }


    }
}

.cookie-bar {
    position: fixed;
    right: 0;
    bottom: 0;
    max-width: 400px;
    background-color: $gray-darker;
    padding: $gutter-sm;
    z-index: $zindex-cookie-bar;
    box-shadow: -5px -5px 13px -4px rgba(0,0,0,0.35);

    a {
        text-decoration: none;

        &:hover {
            color: $brand-primary;

            strong {
                color: inherit;
            }
        }
    }

    h4 {
        margin-top: 0;
    }

    .checkbox label {
        display: flex;
        align-items: center;

        input {
            margin-right: $gutter-xs;
        }
    }
}
