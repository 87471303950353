@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?snf9dt');
    src: url('../fonts/icomoon.eot?snf9dt#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?snf9dt') format('truetype'), url('../fonts/icomoon.woff?snf9dt') format('woff'), url('../fonts/icomoon.svg?snf9dt#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    //line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-shield:before {
    content: "\e915";
}

.icon-demo:before {
  content: "\e914";
}

.icon-refresh:before, .icon-reload:before {
    content: "\e913";
}

.icon-studio:before {
    content: "\e910";
}

.icon-cancel:before {
    content: "\e908";
}

.icon-collapse:before {
    content: "\e909";
}

.icon-confirm:before {
    content: "\e90a";
}

.icon-down:before {
    content: "\e90b";
}

.icon-expand:before {
    content: "\e90c";
}

.icon-left:before {
    content: "\e90d";
}

.icon-right:before {
    content: "\e90e";
}

.icon-up:before {
    content: "\e90f";
}

.icon-warning:before {
    content: "\e911";
}

.icon-attachment:before {
    content: "\e912";
}

.icon-experimental:before {
    content: "\e900";
}

.icon-calendar:before {
    content: "\e901";
}

.icon-play:before {
    content: "\e902";
}

.icon-download:before {
    content: "\e903";
}

.icon-document:before {
    content: "\e904";
}

.icon-link:before {
    content: "\e905";
}

.icon-settings:before {
    content: "\e906";
}

.icon-learn:before {
    content: "\e907";
}
