
#demo-highlight {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: $zindex-walkthrough-overlay;
    #highlight-top, #highlight-right, #highlight-bottom, #highlight-left {
        position: absolute;
        z-index: 1020;
    }

    #highlight-top {
        transition: height .4s ease;
    }

    #highlight-bottom {
        background-color: $highlight-bottom-bg;
        transition: min-height .4s ease, top .4s ease;
        left: 0;
        right: 0;
    }

    #highlight-top {
        top:0;
        background-color: $highlight-bg;
    }

    #highlight-left, #highlight-right {
        top: 0;
        transition: height .4s ease;
        background: $highlight-bg;
        background: -moz-linear-gradient(top, $highlight-bg 95%, $highlight-bottom-bg 100%);
        background: -webkit-linear-gradient(top, $highlight-bg 95%,$highlight-bottom-bg 100%);
        background: linear-gradient(to bottom, $highlight-bg 95%, $highlight-bottom-bg 100%);
        background-position: bottom;
    }
    #highlight-right {
        right: 0;
    }

    #highlight-left {
        left: 0;        
    }
}

#walkthroughProgress {
    flex-grow: 1;
    display: flex;
    counter-reset: progres;
    margin: $gutter-xxs;
    flex-shrink: 1;
    min-width: 70px;
    align-items: center;

    .item {
        margin: 2px;
        height: 4px;
        background-color: $gray-base;
        flex-grow: 1;
        flex-basis: 0;
        transition: height .15s;
        text-align: center;
        overflow: hidden;
        text-decoration: none !important;
        color: $gray-lighter;
        font-weight: bold;
        line-height: 25px;

        &::before {
            opacity: 0;
            counter-increment: section;
            content: counter(section); 
            transition: opacity .15s;

        }

        &:hover {
            height: 25px;

            &::before {
                opacity: 1;
            }
        }

        &.active {
            background-color: $color-5;
        }
    }
}

.walkthrough-active {
    .parameters, .fab-container, .footer-container {
        visibility: hidden;
    }
}


.walkthrough-header {
    position: sticky;
    top: $gutter;
    display: flex;
    flex-wrap: wrap;
    counter-reset: section; 
    

    h1 {
        font-weight: 300;
        margin: 0;
        margin-right: $gutter;
        color: $gray-light;
        i {
            margin-right: $gutter-xs;
        }
        margin-bottom: $gutter-sm;
    }

   

    .walkthrough-nav-container {
        flex-grow: 1;
        margin: -$gutter-xxs;
        display: flex;
        align-items: center;
        .walkthrough-nav {
            background-color: $gray-darker;
            color: $gray-light;
            padding: $gutter-xxs $gutter;
            margin: $gutter-xxs;
            text-decoration: none;
            flex-shrink: 0;
            &:not(.disabled):hover {
                background-color: $color-5;
                color: $gray-lighter;
            }

            &.disabled {
                opacity: .5;
                cursor: default;
            }

            &.nav-next:not(.disabled) {
                background-color: $color-5;
                color: $gray-lighter;

                &:hover {
                    background-color: saturate( lighten( $color-5, 10% ), 10% );
                }
            }
    
        }
    }
}

.walkthrough-description {
    margin: -$gutter/2;
    display: flex;
    flex-wrap: wrap;

    footer {
        
        .nextStep {
            margin-left: $wt-desc-width - 9.5em;
            background-color: $color-5;
            color: $gray-light;
            padding: $gutter-xxs $gutter;
            text-decoration: none;
            color: $gray-lighter;
            display: inline-block;
            white-space: nowrap;

            &:hover {
                color: $gray-lighter;
                background-color: saturate( lighten( $color-5, 10% ), 10% );
            }
        }
    }
    
    header {
        display: flex;
        align-items: center;

        h2 {
            flex-grow: 1;
            margin: 0;
        }
    }

    h3 {
        margin-top: 0;
        line-height: 36px;
    }

    header, h3 {
        padding-bottom: $gutter-sm;
        border-bottom: 1px solid $gray-light;
        color: $gray-lighter;
    }

    .description {
        em {
            color: $gray-lighter;
            //letter-spacing: 0.05em;
        }

        strong {
            color: $gray-lighter;
            font-weight: 900;
        }

        code {
            background-color: rgba($color-4, .2);
            color: $gray-lighter;
            padding: 0 4px;
            display: inline-block;
        }

        ul {
            padding-left: 26px;
        }
    }

    .walkthrough-step, .walkthrough-assets {
        padding: $gutter/2;
    }

    .walkthrough-step {
        width: $wt-desc-width;
        flex-shrink: 1;
    }

    .walkthrough-assets {
        width: 300px;
    }
}

